/* Make sure the antd's CSS reset is imported before introducing our own base styles: */
@import '~antd/es/style/index.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: initial !important;
}

div#root {
  height: 100%;
  min-height: 100vh;
}

#ie-warning ~ div#root {
  height: calc(100% - 52px);
  min-height: calc(100vh - 52px);
}

a {
  color: #000000;
}
a:hover {
  color: #000000;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
